























































import useRegistrationForm from "@/use/registrationForm";
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { v4 as uuid } from "uuid";
import useMisc from "@/use/misc";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props, { emit, root }) {
    const { fieldTypes } = useRegistrationForm({ root });
    const { toCamelCase } = useMisc({ root });

    const form = ref<any>(null);

    const model = ref(props.value);

    const state = reactive({
      open: false,
      valid: false,
    });

    const sizes = [
      { value: 3, text: "1/4" },
      { value: 4, text: "1/3" },
      { value: 6, text: "1/2" },
      { value: 8, text: "2/3" },
      { value: 9, text: "3/4" },
      { value: 12, text: "1/1" },
    ];

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    const open = () => (state.open = true);
    const close = () => (state.open = false);
    const toggle = () => (state.open = !state.open);

    const description = computed(() => {
      switch (model.value.type) {
        case "firstName":
          return root.$tc("rules.firstName");
        case "lastName":
          return root.$tc("rules.lastName");
        case "email":
          return root.$tc("rules.email");

        case "phone":
          return root.$tc("rules.phone");
        case "number":
          return root.$tc("rules.number");
        case "text":
          return root.$tc("rules.text");
        case "textarea":
          return root.$tc("rules.textarea");
        case "radioGroup":
          return root.$tc("rules.radioGroup");
        case "checkboxGroup":
          return root.$tc("rules.checkboxGroup");
        case "select":
          return root.$tc("rules.select");

        default:
          return null;
      }
    });

    const isEditable = computed(() => {
      const type =
        fieldTypes.value[model.value.type as keyof typeof fieldTypes.value];

      if (type) {
        if (typeof type.editableItems === "undefined") return true;
        else return !!type.editableItems;
      } else return true;
    });

    watch(model, (v) => emit("input", v), { deep: true });

    watch(model, () => nextTick(form.value?.validate), { deep: true });
    watch(state, () => nextTick(form.value?.validate), { deep: true });

    onMounted(() => nextTick(form.value?.validate));

    return {
      uuid,
      fieldTypes,
      form,
      model,
      state,
      sizes,
      rules,
      open,
      close,
      toggle,
      description,
      isEditable,
      toCamelCase,
    };
  },
});
